<template>
	<v-card class="sacrifice-card">
		<v-card-text v-if="!success">
			<p class="text-center text-title">Claim (Pre/Private) sale Tokens</p>
			<p class="text-center text-small-light mb-6">Claim your pre and private sale tokens</p>
			<v-row>
				<v-col cols="12">
					<v-divider></v-divider>
				</v-col>
				<v-col cols="6"><span class="text-offwhite text-small-light">Available claim balance:</span></v-col>
				<v-col cols="6"
					><p class="text-offwhite mb-0 text-small-light text-right">{{ claimbalance }} Tokens</p></v-col
				>
		
				<v-col cols="12">
					<v-divider></v-divider>
				</v-col>
			</v-row>
			<v-progress-linear indeterminate color="primary" v-if="loading" class="mt-5" height="25"> Waiting for the transaction to confirm</v-progress-linear>
			<v-btn elevation="0" class="text-dark mb-6 mt-6" :loading="loading" @click="submit()" rounded block color="systemwhite" :disabled="!connected">CLAIM TOKENS</v-btn>
		</v-card-text>
		<v-card-text v-else>
			<p class="text-center text-title">Transaction succesfull</p>
			<p class="text-center text-small-light mb-6">You have successfully claimed your available balance</p>
		
			<v-row>
				<v-col cols="12">
					<v-divider></v-divider>
				</v-col>
				<v-col cols="6"><span class="text-offwhite text-small-light">You have recieved:</span></v-col>
				<v-col cols="6"
					><p class="text-offwhite mb-0 text-small-light text-right">{{ claimbalance }} Tokens</p></v-col
				>
			</v-row>
		
			<v-btn elevation="0" class="text-dark mb-6 mt-6" rounded block color="systemwhite" to="/">Back</v-btn>
		</v-card-text>
	</v-card>
</template>
<script>
export default {
	methods: {
		submit() {
			this.$emit("claim");
		},
	},
	mounted(){
		this.$emit("reset");
	},
	data: () => ({
	
		rate: 1,
		step: 1,
	}),
	
	props: {
		connected: Boolean,
		loading: Boolean,
		status: String,
		txn: String,
		claimbalance: String

	},

}
</script>